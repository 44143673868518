@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?37382099');
  src: url('../font/fontello.eot?37382099#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?37382099') format('woff2'),
       url('../font/fontello.woff?37382099') format('woff'),
       url('../font/fontello.ttf?37382099') format('truetype'),
       url('../font/fontello.svg?37382099#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?37382099#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  // width: 1em;
  margin-right: .3em;
  // margin-right: .3em;
  text-align: center;
  opacity: .8;
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-calendar:before { content: '\e800'; } /* '' */
.icon-calendar-1:before { content: '\e801'; } /* '' */
.icon-search:before { content: '\e805'; } /* '' */
.icon-github-circled:before { content: '\f09b'; } /* '' */
.icon-pinterest-squared:before { content: '\f0d3'; } /* '' */
.icon-youtube:before { content: '\f167'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-flickr:before { content: '\f16e'; } /* '' */
.icon-dribbble:before { content: '\f17d'; } /* '' */
.icon-gitlab:before { content: '\f296'; } /* '' */
.icon-telegram:before { content: '\f2c6'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */
.icon-linkedin-squared:before { content: '\f30c'; } /* '' */
.icon-soundcloud:before { content: '\f348'; } /* '' */
